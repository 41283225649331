.payment-button {
  position: fixed;
  right: 0;
  top: 35%;
  z-index: 50;
  cursor: pointer;

  svg {
    @include mobile {
      width: 40px;
      height: 155px;
    }

    path {
      transition: .5s ease-in-out;
    }

    &:hover {
      path:first-child {
        transition: .5s ease-in-out;
        fill: var(--color-park);
      }
    }
  }
}

.payment-overlay {
  display: none;
  background: rgba(16, 27, 20, .25);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 54;

  &.open {
    display: block;
  }
}

.payment {
  background-color: #EDF6F1;
  padding: 64px 72px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 55;
  transform: translate(100%);
  transition: transform .5s ease-in-out;
  opacity: 1;
  height: 100%;
  max-width: 710px;
  width: 100%;
  overflow-y: scroll;

  @include tablet {
    max-width: 320px;
    padding: 36px 16px;
  }

  @include mobile_small {
    max-width: none;
  }

  &.open {
    transition: transform .5s ease-in-out;
    transform: translate(0);
    opacity: 1;

    .payment-overlay {
      display: block;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }

  &__close {
    position: absolute;
    top: 28px;
    right: 28px;
    cursor: pointer;

    @include tablet {
      top: 16px;
      right: 16px;
    }

    svg {
      @include tablet {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__headline {
    margin-bottom: 4px;

    &-title {
      font-family: Akrobat, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      margin-bottom: 8px;

      @include tablet {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 4px;
      }
    }

    &-subtitle {
      font-size: 18px;
      line-height: 28px;
      color: rgba(16, 27, 20, .8);

      @include tablet {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;

    @include tablet {
      margin: 0;
    }

    &-row {
      width: 100% !important;

      input {
        max-width: 100% !important;
      }
    }

    label:not(.payment__bottom-checkbox) {
      display: block;
      width: calc(50% - 14px);
      margin: 24px 7px 0;

      @include tablet {
        width: 100%;
        margin: 14px 0 0;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px;

        @include tablet {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 4px;
        }

        span {
          color: #458F4E;
        }
      }

      input:not([type="hidden"]), %payment-input {
        // max-width: 270px;
        max-width: 100%;
        width: 100%;
        padding: 16px 20px;
        font-size: 16px;
        line-height: 20px;
        border: 1px solid rgba(16, 27, 20, 0.15);
        background: #fff;
        position: relative;
        z-index: 1;

        &::placeholder {
          color: rgba(16, 27, 20, .4)
        }

        @include tablet {
          max-width: 100%;
          padding: 12px 14px;
          font-size: 14px;
          line-height: 16px;
        }
      }

      span.error {
        position: absolute;
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: #D6021B;
        transform: translateY(-30px);
        transition: opacity ease 3s, transform ease-in-out .5s;
        opacity: 0;
        margin-top: 8px;
      }

      &.error {
        input {
          transition: .5s ease-in;
          border: 1px solid #D6021B;
        }

        .payment__park {
          transition: .5s ease-in;
          border: 1px solid #D6021B;
        }

        span.error {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  &__park {
    position: relative;

    &.active::after {
      transform: rotate(180deg);
    }

    &::after {
      content: '';
      position: absolute;
      top: 25px;
      right: 20px;
      height: 6.27px;
      width: 11.05px;
      display: block;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3033 2.00738L6.53031 6.78035C6.23742 7.07324 5.76254 7.07324 5.46965 6.78035L0.696679 2.00738C0.403786 1.71449 0.403786 1.23961 0.696679 0.946719C0.989573 0.653826 1.46445 0.653826 1.75734 0.946719L5.99998 5.18936L10.2426 0.946721C10.5355 0.653827 11.0104 0.653827 11.3033 0.946721C11.5962 1.23961 11.5962 1.71449 11.3033 2.00738Z' fill='%23101B14'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      transition: 0.3s ease-in-out;
    }

    &-input {
      @extend %payment-input;
      cursor: pointer;
      color: rgba(16, 27, 20, .4);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ul {
      display: none;
      position: absolute;
      list-style: none;
      padding-left: 0;
      border: 1px solid rgba(16, 27, 20, 0.15);
      background: white;
      width: 270px;
      margin-top: 12px;
      padding: 16px 20px;
      z-index: 10;

      &.open {
        display: block;
      }

      li {
        margin-bottom: 12px;
        cursor: pointer;
        width: fit-content;
        font-size: 16px;
        line-height: 20px;
        transition: .3s ease-in-out;

        &:hover {
          color: #458F4E;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.disabled {
          color: rgba(16, 27, 20, .4);
          pointer-events: none;
        }
      }
    }
  }

  &__service {
    position: relative;

    &.active::after {
      transform: rotate(180deg);
    }

    &::after {
      content: '';
      position: absolute;
      top: 25px;
      right: 20px;
      height: 6.27px;
      width: 11.05px;
      display: block;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3033 2.00738L6.53031 6.78035C6.23742 7.07324 5.76254 7.07324 5.46965 6.78035L0.696679 2.00738C0.403786 1.71449 0.403786 1.23961 0.696679 0.946719C0.989573 0.653826 1.46445 0.653826 1.75734 0.946719L5.99998 5.18936L10.2426 0.946721C10.5355 0.653827 11.0104 0.653827 11.3033 0.946721C11.5962 1.23961 11.5962 1.71449 11.3033 2.00738Z' fill='%23101B14'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      transition: 0.3s ease-in-out;
    }

    &-input {
      @extend %payment-input;
      width: 100% !important;
      max-width: 100% !important;
      cursor: pointer;
    }

    ul {
      display: none;
      position: absolute;
      list-style: none;
      padding-left: 0;
      border: 1px solid rgba(16, 27, 20, 0.15);
      background: white;
      width: 100%;
      max-width: 551px;
      margin-top: 12px;
      padding: 16px 20px;
      z-index: 10;

      &.open {
        display: block;
      }

      li {
        margin-bottom: 12px;
        cursor: pointer;
        width: fit-content;
        font-size: 16px;
        line-height: 20px;
        transition: .3s ease-in-out;

        &:hover {
          color: #458F4E;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.disabled {
          color: rgba(16, 27, 20, .4);
          pointer-events: none;
        }
      }
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;

      &-name {
        @extend %payment-input;
        width: 100% !important;
        margin-right: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 497px !important;
      }

      &-delete {
        svg {
          cursor: pointer;
        }
      }
    }
  }

  &__bottom {
    margin-top: 52px;
    padding-top: 28px;
    border-top: 1px solid rgba(16, 27, 20, .1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include tablet {
      margin-top: 32px;
    }

    .disabled {
      cursor: initial;
    }

    .btn {
      @include tablet {
        width: 100%;
        text-align: center;
      }
    }

    button {
      border: none;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }
    }

    &-politic {
      margin-top: 32px;
      width: 100%;
      display: flex;
      align-items: flex-start;

      @include tablet {
        margin-top: 24px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        color: $color-dark;

        @include tablet {
          font-size: 12px;
          line-height: 16px;
        }

        a {
          color: inherit;
          font-size: inherit;
          line-height: inherit;
        }
      }
    }

    &-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;

      & + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
      }

      & + label::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid rgba(16, 27, 20, 0.15);
        border-radius: 2px;
        margin-right: 12px;
        background-repeat: no-repeat;
        background-position: center center;
        // background-size: 50% 50%;
      }

      &.error + label::before {
        border: 1px solid #D6021B;
      }

      &:checked.error + label::before {
        border: 1px solid rgba(16, 27, 20, 0.15);
        background-color: white;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='15' height='11' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.33312 8.64325L12.9931 0.982422L14.1723 2.16076L5.33312 10.9999L0.0297852 5.69659L1.20812 4.51826L5.33312 8.64325Z' fill='%23458F4E'/%3e%3c/svg%3e");
      }

      &:checked + label::before {
        // border-color: #458F4E;
        background-color: white;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='15' height='11' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.33312 8.64325L12.9931 0.982422L14.1723 2.16076L5.33312 10.9999L0.0297852 5.69659L1.20812 4.51826L5.33312 8.64325Z' fill='%23458F4E'/%3e%3c/svg%3e");
      }
    }
  }

  &__total {

    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 22px;
    }

    &-name {
      font-size: 16px;
      line-height: 20px;
      color: #101B14;

      @include mobile {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &-price {
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
      display: flex;
      align-items: center;
    }

    span {
      display: block;
      margin-left: 5px;
    }
  }
}

.range {
  margin: auto;
  -webkit-appearance: none;
  position: relative !important;
  top: -8px;
  overflow: hidden;
  height: 16px;
  // width: 98% !important;
  cursor: pointer;
  border-radius: 0;
  background: transparent !important;
  border: none !important;
  padding: 0 !important;

  &::-webkit-slider-runnable-track {
    background: green;
    height: 1px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    //box-shadow: -100vw 0 0 100vw $color-green;
    background: green;
    border-radius: 50%;
    margin-top: -8px;
  }
}