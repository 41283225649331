.contact-page {
    margin-top: calculateRem(36px);

    &__title {
        font-weight: 500;
        font-size: calculateRem(32px);
        line-height: calculateRem(44px);

        @include tablet {
            font-size: calculateRem(20px);
            line-height: calculateRem(24px);
        }
    }

    &__items {
        margin-top: calculateRem(36px);
        margin-bottom: calculateRem(60px);
        display: flex;

        @include tablet {
            margin-top: calculateRem(16px);
            flex-direction: column;
        }
    }

    &__item {
        width: 25%;
        border-right: 1px solid $color-dark-1;
        padding: 0 calculateRem(32px);

        @include tablet {
            width: 100%;
            border-bottom: 1px solid $color-dark-1;
            padding: calculateRem(20px) 0;
            border-right: none;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
            border: none;
        }

        span {
            display: block;
            margin-bottom: calculateRem(8px);
            font-size: calculateRem(14px);
            line-height: calculateRem(20px);
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $color-dark-6;

            @include tablet {
                font-size: calculateRem(12px);
                line-height: calculateRem(16px);
                margin-bottom: calculateRem(4px);
            }
        }

        p, a {
            font-size: calculateRem(18px);
            line-height: calculateRem(24px);
            color: $color-dark;
            text-decoration: none;
            transition: .3s;

            @include tablet {
                font-size: calculateRem(16px);
                line-height: calculateRem(24px);
            }
        }

        a {
            &:hover {
                color: $color-green;
            }
        }
    }
}

.form-wrapper {
    padding: calculateRem(150px) 0;

    @include tablet {
        padding: 0;
    }
}

.form {
    display: flex;
    justify-content: space-between;

    @include tablet {
        flex-direction: column;
    }

    &__left {
        margin-right: calculateRem(45px);
        max-width: calculateRem(330px);

        @include tablet {
            margin-right: 0;
        }

        &-subtitle {
            margin-top: calculateRem(16px);
            font-size: calculateRem(16px);
            line-height: calculateRem(24px);
            color: $color-dark-8;

            @include tablet {
                margin-top: calculateRem(8px);
                font-size: calculateRem(14px);
                line-height: calculateRem(20px);
            }
        }

        &-link {
            margin-top: calculateRem(60px);
            max-width: calculateRem(245px);
            display: block;
            border: 1px solid rgba(16, 27, 20, 0.15);
            padding: calculateRem(28px);
            color: $color-green;
            text-decoration: none;

            @include tablet {
                margin-top: calculateRem(20px);
                max-width: calculateRem(320px);
                padding: calculateRem(16px);
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: calculateRem(24px);
            }

            span {
                display: block;
                margin-bottom: calculateRem(16px);
                font-weight: 600;
                font-size: calculateRem(16px);
                line-height: calculateRem(24px);
                letter-spacing: 0.01em;
                text-transform: uppercase;

                @include tablet {
                    font-size: calculateRem(13px);
                    line-height: calculateRem(20px);
                    margin-bottom: 0;
                }
            }

            svg {
                &:hover {
                    fill: rgb(69, 143, 78);
                }

                @include tablet {
                    width: calculateRem(40px);
                    height: calculateRem(40px);
                }
            }

        }
    }

    &__right {

        form {
            max-width: calculateRem(735px);

            label {
                display: block;
                font-weight: 500;
                font-size: calculateRem(16px);
                line-height: calculateRem(20px);
                margin-bottom: 12px;
                width: 100%;

                span {
                    color: $color-green
                }
            }

            input:not(input[type="submit"]), textarea {
                background-color: $color-white;
                font-size: calculateRem(16px);
                line-height: calculateRem(20px);
                padding: calculateRem(16px) calculateRem(20px);
                border: 1px solid rgba(16, 27, 20, 0.15);
                width: 100%;
                // max-width: calculateRem(650px);
                font-family: inherit;


                @include tablet {
                    padding: calculateRem(12px) calculateRem(14px);
                    font-size: calculateRem(14px);
                    line-height: calculateRem(16px);
                }

                &::placeholder {
                    color: $color-dark-4;
                }

                &.error {
                    border: 1px solid #D6021B;
                }
            }

            textarea {
                min-height: calculateRem(130px);

                @include tablet {
                    min-height: calculateRem(90px);
                }
            }

            input[type="submit"] {
                color: $color-white;
                font-family: 'Commissioner', sans-serif;
                text-transform: uppercase;
                letter-spacing: 0.01em;
                font-weight: 600;
                font-size: calculateRem(16px);
                line-height: calculateRem(20px);
                border: none;
                padding-top: calculateRem(25px);

                @include tablet {
                    font-size: calculateRem(13px);
                    margin: calculateRem(10px) auto 0;
                    padding: calculateRem(17px) calculateRem(24px) calculateRem(14px);
                    margin-bottom: calculateRem(16px);
                }
            }
        }

        &-row {
            margin-bottom: calculateRem(28px);
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            align-items: flex-start;

            @include tablet {
                margin-bottom: calculateRem(14px);
            }

            &:last-child {
                flex-wrap: nowrap;

                @include tablet {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }

            .btn {
                &.disabled {
                    opacity: .7;
                    cursor: initial;
                }
            }

            p {
                margin-left: calculateRem(28px);
                max-width: calculateRem(410px);
                font-size: calculateRem(14px);
                line-height: calculateRem(20px);
                color: $color-dark-4;

                @include tablet {
                    margin-left: 0;
                    text-align: center;
                    font-size: calculateRem(12px);
                    line-height: calculateRem(16px);
                }

                a {
                    font-size: calculateRem(14px);
                    line-height: calculateRem(20px);
                    color: $color-dark-4;
                    text-decoration: underline;

                    @include tablet {
                        font-size: calculateRem(12px);
                        line-height: calculateRem(16px);
                    }
                }
            }

            .form-error {
                opacity: 0;
            }

            span.error {
                color: #D6021B;
                opacity: 1;
            }
        }

        &-col {
            width: 50%;

            .form-error {
                opacity: 0;
            }

            span.error {
                color: #D6021B;
                opacity: 1;
            }

            @include tablet {
               width: 100%;
               
               &:not(:first-child) {
                   margin-top: calculateRem(14px);
               }
            }
        }

        &-select {
            position: relative;

            &-enter {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $color-white;
                font-size: calculateRem(16px);
                line-height: calculateRem(20px);
                padding: calculateRem(16px) calculateRem(20px);
                border: 1px solid rgba(16, 27, 20, 0.15);
                width: 100%;
                max-width: calculateRem(312px);
                cursor: pointer;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                @include desktop_base {
                    max-width: calculateRem(230px);
                }

                @include tablet {
                    padding: calculateRem(12px) calculateRem(14px);
                    font-size: calculateRem(14px);
                    line-height: calculateRem(20px);
                    max-width: none;
                }

                &.show {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
                
                &.active::after {
                    transform: rotate(180deg);
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 25px;
                    right: 20px;
                    height: 6.27px;
                    width: 11.05px;
                    display: block;
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3033 2.00738L6.53031 6.78035C6.23742 7.07324 5.76254 7.07324 5.46965 6.78035L0.696679 2.00738C0.403786 1.71449 0.403786 1.23961 0.696679 0.946719C0.989573 0.653826 1.46445 0.653826 1.75734 0.946719L5.99998 5.18936L10.2426 0.946721C10.5355 0.653827 11.0104 0.653827 11.3033 0.946721C11.5962 1.23961 11.5962 1.71449 11.3033 2.00738Z' fill='%23101B14'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-size: contain;
                    z-index: 1;
                    transition: 0.3s ease-in-out;

                    @include tablet {
                        top: 20px;
                    }
                  }
            }

            ul {
                position: absolute;
                display: none;
                flex-direction: column;
                list-style: none;
                padding-left: 0;
                padding: calculateRem(16px) calculateRem(20px);
                border: 1px solid rgba(16, 27, 20, 0.15);
                width: 100%;
                max-width: calculateRem(312px);
                margin-top: calculateRem(12px);
                background: $color-white;

                @include tablet {
                    max-width: none;
                }

                li {
                    display: inline-block;
                    font-size: calculateRem(16px);
                    line-height: calculateRem(20px);
                    margin-bottom: calculateRem(12px);
                    cursor: pointer;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover {
                        color: $color-green;
                    }

                    &.disable {
                        color: $color-dark-4;
                        pointer-events: none;
                    }
                }
            }


            ul.open {
                display: flex;
            }
        }

    }
}
