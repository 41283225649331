// colors
$color-white: #fff;
$color-white-5: rgba(255, 255, 255, .5);
$color-dark: rgba(16, 27, 20, 1); // #101B14
$color-dark-1: rgba(16, 27, 20, .1);
$color-dark-4: rgba(16, 27, 20, .4);
$color-dark-5: rgba(16, 27, 20, .5);
$color-dark-6: rgba(16, 27, 20, .6);
$color-dark-8: rgba(16, 27, 20, .8);
$color-green: rgba(69, 143, 78, 1);
$color-light-green: rgba(237, 246, 241, 1);



/* font */
$Akrobat: 'Akrobat';
$Commissioner: 'Commissioner';


/* size */

@function calculateRem($size) {
    @return $size / 16px * 1rem;
}
