* {
  margin: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  font-size: 100%;
}

body {
  font-family: Commissioner, sans-serif;
  font-size: 16px;
  overflow-x: hidden;
  color: #101b14;
}

section {
  position: relative;
}

.hidden {
  overflow: hidden;
}

.no-scroll {
  overflow: hidden;
}

.mt {
  &-5 {
    margin-top: 5px !important;
  }
}

.container {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  //height: auto;

  @include desktop {
    padding-left: 60px;
    padding-right: 60px;
  }

  @include tablet_small {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.btn {
  display: block;
  background-color: transparent;
  background-image: url(../images/static/btn-green.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 22px 32px 19px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
}

.title {
  font-family: Akrobat, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 100px;
  line-height: 100px;
  letter-spacing: -0.02em;
  text-transform: uppercase;

  &-margin-top {
    margin-top: 7.5rem;

    @include tablet {
      margin-top: calculateRem(48px);
    }
  }

  @include tablet {
    font-size: 68px;
    line-height: 76px;
  }

  @include mobile {
    font-size: 44px;
    line-height: 48px;
  }
}

.title-h2 {
  font-family: Akrobat, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 68px;
  line-height: 76px;
  letter-spacing: -0.02em;
  text-transform: uppercase;

  @include mobile {
    font-size: 32px;
    line-height: 36px;
  }
}

:root {
  --color-park: #458f4e;
  --bg-park: #458f4e;
}

.color-1 {
  --color-park: #006ea6;
  --bg-park: #afb420;
  --figure-2: #EDF6F1;
}

.color-2 {
  --color-park: #62aaea;
  --bg-park: #df9c9d;
}

.color-3 {
  --color-park: #4583b1;
  --bg-park: #de7952;
}

.color-4 {
  --color-park: #189080;
  --bg-park: #e3c192;
}

.color-5 {
  --color-park: #80ad5d;
  --bg-park: #ac8557;
}

::selection {
  // color: var(--color-park); /* Цвет текста */
  background: var(--bg-park); /* Цвет фона */
}

h1,
%h1,
.h1 {
  font-family: $Akrobat, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: calculateRem(100px);
  line-height: calculateRem(100px);
  letter-spacing: -0.02em;
  text-transform: uppercase;

  @include tablet {
    font-size: calculateRem(44px);
    line-height: calculateRem(48px);
  }
}

h2,
%h2,
.h2 {
  font-family: $Akrobat, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: calculateRem(68px);
  line-height: calculateRem(76px);
  letter-spacing: -0.02em;
  text-transform: uppercase;

  @include tablet {
    font-size: calculateRem(32px);
    line-height: calculateRem(36px);
  }
}

h3,
%h3,
.h3 {
  font-family: $Akrobat, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: calculateRem(56px);
  line-height: calculateRem(64px);
  letter-spacing: -0.02em;
  text-transform: uppercase;

  @include tablet {
    font-size: calculateRem(24px);
    line-height: calculateRem(28px);
  }
}

h4,
%h4,
.h4 {
  font-family: $Commissioner, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: calculateRem(32px);
  line-height: calculateRem(44px);
}

h5,
%h5,
.h5 {
  font-family: $Commissioner, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: calculateRem(24px);
  line-height: calculateRem(32px);
}

h6,
%h6,
.h6 {
  font-family: $Commissioner, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: calculateRem(18px);
  line-height: calculateRem(22px);
}

.show-more {
  display: inline-flex;
  align-items: center;
  color: $color-green;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  font-weight: 600;
  font-size: calculateRem(14px);
  line-height: calculateRem(20px);
  text-decoration: none;
  cursor: pointer;

  @include mobile {
    display: none;
  }

  &.mobile {
    display: none;

    @include mobile {
      display: inline-flex;
      margin: 20px auto;

      &:after {
        content: '';
        background: url('data:image/svg+xml;charset=utf8,%3Csvg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle opacity="0.2" cx="30" cy="30" r="29.5" stroke="%23458F4E"/%3E%3Cpath d="M42.7071 29.2929C43.0976 29.6834 43.0976 30.3166 42.7071 30.7071L36.3431 37.0711C35.9526 37.4616 35.3195 37.4616 34.9289 37.0711C34.5384 36.6805 34.5384 36.0474 34.9289 35.6569L40.5858 30L34.9289 24.3431C34.5384 23.9526 34.5384 23.3195 34.9289 22.9289C35.3195 22.5384 35.9526 22.5384 36.3431 22.9289L42.7071 29.2929ZM18 29L42 29V31L18 31V29Z" fill="%23458F4E"/%3E%3C/svg%3E') center no-repeat;
        margin-right: -30px;
      }
    }
  }

  &:after {
    content: "";
    background: url('data:image/svg+xml;charset=utf8,%3Csvg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle opacity="0.2" cx="30" cy="30" r="29.5" stroke="%23458F4E"/%3E%3Cpath d="M42.7071 29.2929C43.0976 29.6834 43.0976 30.3166 42.7071 30.7071L36.3431 37.0711C35.9526 37.4616 35.3195 37.4616 34.9289 37.0711C34.5384 36.6805 34.5384 36.0474 34.9289 35.6569L40.5858 30L34.9289 24.3431C34.5384 23.9526 34.5384 23.3195 34.9289 22.9289C35.3195 22.5384 35.9526 22.5384 36.3431 22.9289L42.7071 29.2929ZM18 29L42 29V31L18 31V29Z" fill="%23458F4E"/%3E%3C/svg%3E') no-repeat;
    display: block;
    width: calculateRem(60px);
    height: calculateRem(60px);
    transition: 0.5s ease-in-out;
    margin-left: calculateRem(16px);
    background-size: contain;

    @include tablet {
      margin-left: 0;
      //width: calculateRem(40px);
      //height: calculateRem(40px);
    }
  }

  &:hover:after {
    //background: url(../images/static/button-next-hover.svg) no-repeat;
    background: url('data:image/svg+xml;charset=utf8,%3Csvg width="60" height="60" viewBox="0 0 60 60" fill="%23458F4E" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle opacity="0.2" cx="30" cy="30" r="29.5" stroke="%23458F4E"/%3E%3Cpath d="M42.7071 29.2929C43.0976 29.6834 43.0976 30.3166 42.7071 30.7071L36.3431 37.0711C35.9526 37.4616 35.3195 37.4616 34.9289 37.0711C34.5384 36.6805 34.5384 36.0474 34.9289 35.6569L40.5858 30L34.9289 24.3431C34.5384 23.9526 34.5384 23.3195 34.9289 22.9289C35.3195 22.5384 35.9526 22.5384 36.3431 22.9289L42.7071 29.2929ZM18 29L42 29V31L18 31V29Z" fill="%23458F4E"/%3E%3C/svg%3E') no-repeat;
    background-size: contain;

    @include mobile {
      background: url('data:image/svg+xml;charset=utf8,%3Csvg width="40" height="40" viewBox="0 0 60 60" fill="%23458F4E" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle opacity="0.2" cx="30" cy="30" r="29.5" stroke="%23458F4E"/%3E%3Cpath d="M42.7071 29.2929C43.0976 29.6834 43.0976 30.3166 42.7071 30.7071L36.3431 37.0711C35.9526 37.4616 35.3195 37.4616 34.9289 37.0711C34.5384 36.6805 34.5384 36.0474 34.9289 35.6569L40.5858 30L34.9289 24.3431C34.5384 23.9526 34.5384 23.3195 34.9289 22.9289C35.3195 22.5384 35.9526 22.5384 36.3431 22.9289L42.7071 29.2929ZM18 29L42 29V31L18 31V29Z" fill="%23458F4E"/%3E%3C/svg%3E') center no-repeat;
    }
  }
}

.show-more-button {
  svg {
    transition: .7s ease-in-out;

    &:hover {
      fill: $color-green;

      .show-more-button-text {
        fill: $color-white;
      }
    }
  }

  @include tablet {
    display: flex;
    justify-content: center;

    svg {
      width: calculateRem(150px);
    }
  }
}
