.page-content {
    padding: calculateRem(60px) 0 calculateRem(160px);
    display: flex;

    @include tablet {
        flex-direction: column;
        padding: calculateRem(30px) 0 0;
    }

    @include mobile {
        padding: calculateRem(12px) 0 0;
    }

    .sidebar {
        width: 245px;
        margin-right: calculateRem(90px);

        &:not(&.back-page) {
            @include tablet {
                border: 1px solid rgba(16, 27, 20, 0.15);
                background: white;
                padding: calculateRem(12px) calculateRem(14px);
                width: 100%;
                position: relative;
                cursor: pointer;
                margin-bottom: calculateRem(20px);
    
                &::after {
                    content: '';
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    height: 6.27px;
                    width: 11.05px;
                    display: block;
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3033 2.00738L6.53031 6.78035C6.23742 7.07324 5.76254 7.07324 5.46965 6.78035L0.696679 2.00738C0.403786 1.71449 0.403786 1.23961 0.696679 0.946719C0.989573 0.653826 1.46445 0.653826 1.75734 0.946719L5.99998 5.18936L10.2426 0.946721C10.5355 0.653827 11.0104 0.653827 11.3033 0.946721C11.5962 1.23961 11.5962 1.71449 11.3033 2.00738Z' fill='%23101B14'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-size: contain;
                    z-index: 1;
                    transition: 0.3s ease-in-out;
                }
            }
        }

        &.back-page {
            @include mobile {
                border: 0;
                background: transparent;
                padding: 0;
                width: 100%;
                position: relative;
                cursor: initial;

                & a::after {
                    content: '';
                    display: none;
                }

                & span::after {
                    content: '';
                    display: none;
                }
            }
        }

        &.back-page a {
            display: flex;
            align-items: center;
            text-decoration: none;
            font-weight: 600;
            font-size: calculateRem(14px);
            line-height: calculateRem(20px);
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $color-green;

            @include tablet {
                font-size: calculateRem(11px);
                line-height: calculateRem(13px);
                letter-spacing: 0.01em;
                text-transform: uppercase;
                margin-bottom: calculateRem(20px);
            }

            svg {
                @include tablet {
                    width: calculateRem(32px);
                    height: calculateRem(32px);
                } 
            }

            span {
                margin-left: calculateRem(16px);

                @include tablet {
                    margin-left: calculateRem(8px);
                }
            }
        }

        &-title {
            display: none;

            @include tablet {
                display: block;
            }
        }

        ul {
            padding: 0;
            list-style: none;

            @include tablet {
                display: none;
                position: absolute;
                top: calculateRem(50px);
                left: 0;
                background-color: $color-white;
                width: 100%;
                border: 1px solid rgba(16, 27, 20, 0.15);
                padding: calculateRem(12px) calculateRem(14px);
            }

            li {
                margin-bottom: calculateRem(18px);
                display: flex;
                align-items: center;

                @include mobile {
                    margin-bottom: calculateRem(14px);
                }

                a {
                    font-weight: 500;
                    font-size: calculateRem(18px);
                    line-height: calculateRem(22px);
                    color: $color-dark-6;
                    text-decoration: none;
                    transition: ease-in-out .5s;

                    @include mobile {
                        font-size: calculateRem(14px);
                    }

                    &:hover {
                        color: $color-dark;
                    }
                }

                &.active {
                    color: $color-dark;

                    a {
                        color: inherit;
                    }

                    &:before {
                        content: '';
                        background-color: $color-green;
                        display: block;
                        width: calculateRem(20px);
                        min-width: calculateRem(20px);
                        height: calculateRem(2px);
                        margin-right: calculateRem(8px);
                    }
                }
            }

            &.active {
                display: block;
            }
        }
    }

    .content {
        width: calc(100% - (245px + 90px));
        color: $color-dark-8;
        font-size: calculateRem(18px);
        line-height: calculateRem(28px);
        //max-width: calculateRem(900px);

        @include tablet {
            width: 100%;
        }

        &-block {
            &__buttons {
                margin-top: calculateRem(14px);
            }
        }

        &-date {
            font-size: calculateRem(16px);
            line-height: calculateRem(20px);
            margin-bottom: calculateRem(8px);
            color: $color-dark-6;

            @include tablet {
                font-size: calculateRem(13px);
                line-height: calculateRem(20px);
            }
        }

        &-headline {
            font-weight: 500;
            font-size: calculateRem(32px);
            margin-bottom: calculateRem(44px);
            line-height: calculateRem(44px);
            color: #101B14;

            @include tablet {
                font-size: calculateRem(20px);
                line-height: calculateRem(26px);
            }

            @include mobile {
                font-size: calculateRem(18px);
                line-height: calculateRem(24px);
                margin-bottom: calculateRem(16px);
            }
        }

        p {
            font-size: calculateRem(16px);
            line-height: calculateRem(24px);
            margin-bottom: calculateRem(20px);

            @include tablet {
                font-size: calculateRem(14px);
                line-height: calculateRem(20px);
            }

            @include mobile {
                font-size: calculateRem(14px);
                line-height: calculateRem(20px);
                margin-bottom: calculateRem(12px);
            }
        }

        img {
            max-width: 100%;
            //margin: 40px 0;
            //
            //@include tablet {
            //    margin: 20px 0;
            //}
        }

        a {

            color: #458f4e;

            &:hover {
                color: #80AD5D;
            }
        }

        .img {
            margin-top: calculateRem(20px);
            margin-bottom: calculateRem(20px);
            max-width: 100%;
        }

        ul {
            padding-top: calculateRem(12px);
            padding-left: calculateRem(20px);
            margin-bottom: calculateRem(40px);

            li {
                margin-bottom: calculateRem(8px);

                @include tablet {
                    font-size: calculateRem(14px);
                    line-height: calculateRem(20px);
                }
            }
        }

        &-document {
            padding-top: calculateRem(20px);
            padding-bottom: calculateRem(20px);
            display: flex;
            flex-wrap: wrap;
            margin: calculateRem(-8px);

            &__headline {
                width: 100%;
                font-weight: 500;
                font-size: calculateRem(32px);
                line-height: calculateRem(44px);
                padding: calculateRem(20px) 0 calculateRem(20px);
            }

            &__item {
                margin: calculateRem(8px);
                border: 1px solid rgba(16, 27, 20, 0.15);
                background: $color-white;
                padding: calculateRem(25px);
                display: flex;
                width: calc(50% - 16px);
                text-decoration: none;
                color: $color-dark;
                transition: ease-in-out .3s;

                @include tablet {
                    padding: calculateRem(16px);
                }

                @include mobile {
                    width: 100%;
                }

                &:hover {
                    background-color: #F7F7F8;
                }

                &-text {
                    margin-left: calculateRem(23px);
                    max-width: calculateRem(325px);
                    display: flex;
                    flex-direction: column;

                    p {
                        font-weight: 500;
                        font-size: calculateRem(16px);
                        line-height: calculateRem(20px);
                        margin-bottom: calculateRem(8px);

                        @include tablet {
                            font-size: calculateRem(14px);
                        }
                    }

                    span {
                        font-size: calculateRem(14px);
                        line-height: calculateRem(16px);
                        color: $color-dark-6;

                        @include tablet {
                            font-size: calculateRem(11px);
                        }
                    }
                }
            }
        }

        &-list {
            margin-bottom: calculateRem(40px);
            overflow-x: scroll;

            &__head {
                display: flex;
                min-width: calculateRem(550px);
                width: 100%;

                .content-list__col {
                    width: 25%;
                    font-size: calculateRem(14px);
                    color: $color-dark-6;
                    padding: calculateRem(20px) 0;
                    border-bottom: 1px solid $color-dark-1;
                    text-transform: uppercase;

                    @include tablet {
                        font-size: calculateRem(12px);
                        line-height: calculateRem(16px);
                    }

                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 30%;
                    }

                    &:nth-child(3) {
                        width: 20%;
                    }

                    &:nth-child(4) {
                        width: 25%;
                    }
                }
            }

            &__row {
                display: flex;
                border-bottom: 1px solid $color-dark-1;
                min-width: calculateRem(550px);
                width: 100%;

                &:last-child {
                    border: none;
                }

                .content-list__col {
                    width: 25%;
                    padding: calculateRem(20px) 0;

                    @include tablet {
                        font-size: calculateRem(12px);
                        line-height: calculateRem(16px);
                    }

                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 30%;
                    }

                    &:nth-child(3) {
                        width: 20%;
                    }

                    &:nth-child(4) {
                        width: 25%;
                    }
                }
            }
        }

        &-table-two-column {
            display: flex;
            flex-wrap: wrap;
            margin: 0 calculateRem(-20px);
            // position: relative;

            &__item {
                width: calc(50% - 40px);
                margin: 0 calculateRem(20px);
                border-bottom: 1px solid $color-dark-1;
                padding: calculateRem(28px) 0;

                @include mobile {
                    width: 100%;
                    padding: calculateRem(20px) 0;

                    &:first-child {
                        padding-top: 0;
                    }
                }

                span {
                    display: block;
                    font-size: calculateRem(14px);
                    color: $color-dark-6;
                    text-transform: uppercase;
                    letter-spacing: 0.01em;
                    line-height: calculateRem(20px);

                    @include mobile {
                        font-size: calculateRem(12px);
                        line-height: calculateRem(16px);
                    }
                }

                p {
                    margin-top: calculateRem(8px);
                    margin-bottom: 0;

                    @include mobile {
                        margin-top: calculateRem(4px);
                    }
                }
            }
        }
    }

    ul.partners-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
    
        li {
            width: calc(100% / 5);

            @include tablet {
                width: calc(100% / 3);
            }

            img {
                max-width: 90%;

                @include tablet {
                    margin: 0;
                    max-width: 90%;
                }
            }
        }
    }

    .diractorate-question__item {
        width: 100%;
    }

    .contests-items {
        flex-wrap: wrap;

        &__item {
            max-width: calc(100% / 3 - 1.125rem);
            margin-bottom: calculateRem(30px);

            @include mobile {
                max-width: none;
            }
        }
    }
}

.button-center {
    margin-top: calculateRem(56px);
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
        margin-top: calculateRem(28px);

        svg {
            width: 171px;
            height: 51px;
        }
    }
}


