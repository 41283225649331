.hero-park {
    header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.header-inner {
    position: relative;
    padding-bottom: 6px;
    background: url(../images/static/header-bg.svg) center no-repeat;
    background-size: cover;
    margin-bottom: calculateRem(120px);

    @include tablet {
        margin-bottom: calculateRem(48px);
    }

    .header {
        position: relative;
        color: $color-dark;
    }
}

header {
    .header {
        padding: 24px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-white;

        @include tablet {
            padding: 12px 0;
        }

        &__logo {
            @include tablet {
                max-width: 148px;

                svg {
                    max-width: 100%;
                }
            }
        }

        &__menu {
            list-style: none;
            padding-left: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            li {
                margin-right: 32px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    text-decoration: none;
                    color: inherit;
                    position: relative;
                    font-weight: 600;
                    font-size: calculateRem(16px);
                    line-height: calculateRem(20px);
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
                        opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
                    padding-bottom: calculateRem(4px);

                    &:hover::after {
                        background-color: $color-green;
                        transform: scaleX(1);
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        height: 2px;
                        background-color: transparent;
                        bottom: -4px;
                        left: 0;
                        right: 0;
                        transform: scaleX(0);
                        transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
                            opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
                    }
                }
            }

            @include tablet {
                display: none;
            }

            &-mobile {
                display: none;
                cursor: pointer;

                @include tablet {
                    display: block;
                }
            }
        }

        // mobile
        &__mobile {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            z-index: 100;
            transform: translate(-100%);
            transition: 0.5s ease-in-out;
            opacity: 0;
            overflow: hidden;

            &.open {
                opacity: 1;
                transform: translate(0);
            }

            &-header {
                padding: 15px 16px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;

                &:after {
                    content: "";
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='1440' height='26' viewBox='0 0 1440 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath opacity='0.1' d='M-4.37117e-07 11.0001L114 25L286 7.0001L502 25L728 9.99999L1372 25L1427 1.00006L1440 5.00001' stroke='%23101B14' stroke-miterlimit='10'/%3e%3c/svg%3e");
                    background-position: center;
                    height: calculateRem(16px);
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    left: 0;
                }
            }

            &-close {
                cursor: pointer;
            }

            &-menu {
                position: relative;
                padding: 48px 16px;
                height: 100%;
                display: flex;
                flex-direction: column;

                &:before {
                    content: "";
                    background: url(../images/static/figure-mobile-menu.svg) no-repeat;
                    position: absolute;
                    display: block;
                    right: 0;
                    bottom: 70px;
                    width: 320px;
                    height: 455px;
                    z-index: -1;
                }

                &-item {
                    margin-bottom: 32px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                a {
                    color: #101b14;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }
        }
    }
}
