@font-face {
    font-family: 'Commissioner';
    src: url(../fonts/Commissioner/Commissioner-ExtraBold.ttf);
    font-weight: 800;
};

@font-face {
    font-family: 'Commissioner';
    src: url(../fonts/Commissioner/Commissioner-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Commissioner';
    src: url(../fonts/Commissioner/Commissioner-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'Commissioner';
    src: url(../fonts/Commissioner/Commissioner-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Commissioner';
    src: url(../fonts/Commissioner/Commissioner-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Commissioner';
    src: url(../fonts/Commissioner/Commissioner-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Commissioner';
    src: url(../fonts/Commissioner/Commissioner-ExtraLight.ttf);
    font-weight: 200;
}

@font-face {
    font-family: 'Commissioner';
    src: url(../fonts/Commissioner/Commissioner-Thin.ttf);
    font-weight: 100;
}

@font-face {
    font-family: 'Akrobat';
    src: url(../fonts/Akrobat/Akrobat-Black.otf);
    font-weight: 900;
}

@font-face {
    font-family: 'Akrobat';
    src: url(../fonts/Akrobat/Akrobat-ExtraBold.otf);
    font-weight: 800;
}

@font-face {
    font-family: 'Akrobat';
    src: url(../fonts/Akrobat/Akrobat-Bold.otf);
    font-weight: 700;
}

@font-face {
    font-family: 'Akrobat';
    src: url(../fonts/Akrobat/Akrobat-SemiBold.otf);
    font-weight: 600;
}

@font-face {
    font-family: 'Akrobat';
    src: url(../fonts/Akrobat/Akrobat-Regular.otf);
    font-weight: 400;
}

@font-face {
    font-family: 'Akrobat';
    src: url(fonts/Akrobat/Akrobat-Light.otf);
    font-weight: 300;
}

@font-face {
    font-family: 'Akrobat';
    src: url(../fonts/Akrobat/Akrobat-ExtraLight.otf);
    font-weight: 200;
}

@font-face {
    font-family: 'Akrobat';
    src: url(../fonts/Akrobat/Akrobat-Thin.otf);
    font-weight: 100;
}
