.anti-corruption {
  padding-top: calculateRem(80px);
  padding-bottom: calculateRem(80px);
  display: flex;

  @include tablet {
    flex-direction: column-reverse;
    padding: calculateRem(40px) 0;
  }

  &__img {
    @include desktop {
      img {
        max-width: 100%;
      }
    }
  }

  &__block {
    margin-left: calculateRem(60px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet {
      padding: 0 calculateRem(16px);
      margin-left: 0;
    }
  }

  &__title {
    @extend %h2;
    margin-bottom: calculateRem(36px);
    max-width: calculateRem(505px);

    @include tablet {
      margin-bottom: calculateRem(12px);
    }
  }

  &__text {
    max-width: calculateRem(356px);
    margin-bottom: calculateRem(48px);
    color: $color-dark-8;
    line-height: calculateRem(24px);

    @include tablet {
      margin-bottom: calculateRem(28px);
      font-size: calculateRem(14px);
      line-height: calculateRem(20px);
    }
  }

  &__button {
    @include tablet {
      margin-bottom: calculateRem(40px);
    }
  }
}
