.video-slider {
  padding-top: 80px;

  @include mobile {
    padding-top: 0;
  }

  .container {
    @include mobile {
      padding: 0;
    }
  }

  &__title {
    margin-left: 150px;
    margin-bottom: 48px;
    max-width: 573px;
    font-family: Akrobat, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 100px;
    line-height: 100px;
    letter-spacing: -0.02em;
    text-transform: uppercase;

    @include tablet {
      font-size: 68px;
      line-height: 76px;
      max-width: 400px;
      margin-left: 100px;
    }

    @include tablet_small {
      margin-left: 0;
    }

    @include mobile {
      max-width: 260px;
      font-size: 44px;
      line-height: 48px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__slide {
    position: relative;
  }

  &__poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    top: 0;
    left: 0;
    z-index: 5;

    &-wrapper {
      max-width: 1320px;
      max-height: 734px;
      position: relative;
      display: flex;
      align-items: center;
    }
  }

  &__button-wrapper {
    position: absolute;
    z-index: 10;
    //top: calc(50% - 60px);
    //left: calc(50% - 74px);
    margin: auto;
    top: calc(53% - 74px);
    right: calc(50% - 74px);
    bottom: calc(50% - 74px);
    left: calc(50% - 74px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    @include tablet {
      width: calculateRem(80px);
      height: calculateRem(80px);
    }

    &:hover {
      opacity: .6;
      cursor: pointer;
    }
  }

  &-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  //iframe, div, img {
  //    width: 100%;
  // height: 734px;
  //}

  // video[poster] {
  //     object-fit: cover;
  // }

  .swiper-container {
    @include mobile {
      overflow: visible;
    }
  }

  //src="images/dynamic/slide-park-1.jpg"

  .swiper-button-next {
    right: 36px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle opacity='0.2' cx='40' cy='40' r='39.5' stroke='white'/%3e%3cpath d='M27.2929 39.2929C26.9024 39.6834 26.9024 40.3166 27.2929 40.7071L33.6569 47.0711C34.0474 47.4616 34.6805 47.4616 35.0711 47.0711C35.4616 46.6805 35.4616 46.0474 35.0711 45.6569L29.4142 40L35.0711 34.3431C35.4616 33.9526 35.4616 33.3195 35.0711 32.9289C34.6805 32.5384 34.0474 32.5384 33.6569 32.9289L27.2929 39.2929ZM52 39H28V41H52V39Z' fill='white'/%3e%3c/svg%3e ");
    transform: rotate(180deg);
    width: 80px;
    height: 80px;

    // &.swiper-button-disabled {
    //     background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle opacity='0.2' cx='40' cy='40' r='39.5' stroke='white'/%3e%3cpath d='M27.2929 39.2929C26.9024 39.6834 26.9024 40.3166 27.2929 40.7071L33.6569 47.0711C34.0474 47.4616 34.6805 47.4616 35.0711 47.0711C35.4616 46.6805 35.4616 46.0474 35.0711 45.6569L29.4142 40L35.0711 34.3431C35.4616 33.9526 35.4616 33.3195 35.0711 32.9289C34.6805 32.5384 34.0474 32.5384 33.6569 32.9289L27.2929 39.2929ZM52 39H28V41H52V39Z' fill='white'/%3e%3c/svg%3e ");
    // }

    &::after {
      content: '';
    }

    @include tablet {
      background-size: contain;
      width: 40px;
      height: 40px;
    }

    @include mobile {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 36px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle opacity='0.2' cx='40' cy='40' r='39.5' stroke='white'/%3e%3cpath d='M27.2929 39.2929C26.9024 39.6834 26.9024 40.3166 27.2929 40.7071L33.6569 47.0711C34.0474 47.4616 34.6805 47.4616 35.0711 47.0711C35.4616 46.6805 35.4616 46.0474 35.0711 45.6569L29.4142 40L35.0711 34.3431C35.4616 33.9526 35.4616 33.3195 35.0711 32.9289C34.6805 32.5384 34.0474 32.5384 33.6569 32.9289L27.2929 39.2929ZM52 39H28V41H52V39Z' fill='white'/%3e%3c/svg%3e ");
    height: 80px;
    width: 80px;

    // &.swiper-button-disabled {
    //     background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle opacity='0.2' cx='40' cy='40' r='39.5' stroke='white'/%3e%3cpath d='M27.2929 39.2929C26.9024 39.6834 26.9024 40.3166 27.2929 40.7071L33.6569 47.0711C34.0474 47.4616 34.6805 47.4616 35.0711 47.0711C35.4616 46.6805 35.4616 46.0474 35.0711 45.6569L29.4142 40L35.0711 34.3431C35.4616 33.9526 35.4616 33.3195 35.0711 32.9289C34.6805 32.5384 34.0474 32.5384 33.6569 32.9289L27.2929 39.2929ZM52 39H28V41H52V39Z' fill='white'/%3e%3c/svg%3e ");
    // }

    &::after {
      content: '';
    }

    @include tablet {
      background-size: contain;
      width: 40px;
      height: 40px;
    }

    @include mobile {
      display: none;
    }
  }

  .swiper-pagination {
    display: none;
    bottom: -32px;

    @include mobile {
      display: block;
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background: transparent;
      border: 1px solid var(--color-park);

      &.swiper-pagination-bullet-active {
        background: var(--color-park);
      }
    }
  }
}