main {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 60px;
    //overflow-x: hidden;
    overflow: hidden;
    position: relative;

    @include tablet {
        min-height: 640px;
        height: 100%;
        // padding-bottom: 0;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -2px;
      background: url(../images/static/slider-figure.svg) 100% 0 no-repeat;
      background-size: cover;
      z-index: 1;
      width: 45%;
      height: 40px;

      @include tablet_small {
        height: calculateRem(10px);
      }
  }
}

.hero {
    display: flex;
    justify-content: space-between;
    min-height: 300px;

    &-wrap {

      .container:first-child {
        @include tablet {
          padding-right: 0;
        }
      }
    }

    @include desktop_extra_small {
        flex-direction: column;
    }

    &__text {
        min-width: calc(100% - (100% - 555px));
        max-width: calc(100% - (100% - 555px));
        margin-right: 115px;
        color: white;
        margin-top: -50px;

        @include desktop_extra_small {
          min-width: auto;
          max-width: 555px;
          margin-right: 0;
        }

        @include tablet {
            margin-top: 210px;
        }

        p {
            margin-bottom: 12px;
            font-size: 1rem;
            font-weight: 400;
            line-height: 20px;
            text-transform: uppercase;
            letter-spacing: 0.01em;

            @include tablet {
                font-size: 12px;
                line-height: 16px;
            }
        }

        h1 {
            margin-bottom: 32px;
            font-family: Akrobat;
            font-style: normal;
            font-weight: 800;
            font-size: 80px;
            line-height: 88px;
            letter-spacing: -0.02em;
            text-transform: uppercase;

            @include tablet {
                font-size: 40px;
                line-height: 44px;
                margin-bottom: 12px;
            }
        }

        a {
            display: inline-block;
            text-decoration: none;
            text-transform: uppercase;
            color: white;
            font-weight: 600;
            font-size: 1rem;
            line-height: 20px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            padding: 16px 24px;
            border: 1px solid white;
            cursor: pointer;
            transition: .5s ease-in-out;

            &:hover {
              transition: .5s ease-in-out;
              color: #458f4e;
              background: #fff;
            }

            @include tablet {
                padding: 10px 12px;
                margin-bottom: 32px;
                font-size: 11px;
                line-height: 13px;
            }
        }
    }
    
    &__slider {
        max-width: 100%;
        
        @include desktop_extra_small {
          margin: 30px 0 80px 0;
        }

        &-item {
            height: 100%;
            background-position: center;
            background-size: cover;
            color: $color-white;
            padding: 12px 16px;
            display: flex;
            align-items: flex-end;
            cursor: pointer;
            filter: drop-shadow(5px 5px 20px rgba(16, 27, 20, 0.25));

            &:hover {
              opacity: 0.9;
            }

            @include tablet {
                padding: 8px 6px;
            }

            p {
                font-family: Akrobat;
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 28px;
                letter-spacing: -0.02em;
                text-transform: uppercase;

                @include tablet {
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }

        .swiper-slide {
            height: 200px;

            @include tablet {
                height: 112px;
            }
        }
  }

  &__wrapper-nav {
    position: relative;
    max-width: calc(100% - 670px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-top: -60px;

    @include desktop_extra_small {
      max-width: 100%;
    }

    .slider-number {
      display: flex;
      align-items: center;
      color: $color-white;
      font-size: 20px;
      line-height: 24px;

      @include mobile {
        font-size: 13px;
        line-height: 20px;
      }

      .slider-number_line {
        margin: 0 3px;
      }
    }

    .swiper-pagination {
      position: relative;
      width: 65%;
      height: 2px;
      background: rgba(255, 255, 255, .2);

      @include tablet_small {
        width: 40%;
      }
  
      .swiper-pagination-progressbar-fill {
        height: 2px;
        background: $color-white;
      }
    }
  }

  &__slider-buttons {
    display: flex;
    justify-content: space-between;
    width: calc(60px + 60px + 8px);

    @include tablet_small {
      width: calc(40px + 40px + 4px);
    }

    svg {
      cursor: pointer;

      @include tablet_small {
        width: 40px;
        height: 40px;
      }

      &:hover {
        circle {
          fill: $color-white;
          opacity: 0.33;
        }
      }
    }
  }
}







// .hero-animate-bg {
//     width: 60%;
//     height: 70%;
//     background-color: yellow;
//     animation-name: fullImage;
//     animation-duration: 1s;
//     position: absolute;
//     transform: translateX(20%) translateY(30%);
//     transition: .5s;
// }

// @keyframes fullImage {
//     from {
//         width: 60%;
//         height: 70%;
//         position: fixed;
//     }
//     to {
//         transform: translateX(0%) translateY(0%);
//         width: 100%;
//         height: 100%;
//     }
// }

// .anim-text {
//     p {
//         animation-name: show;
//         animation-duration: 1s;
//     }
// }

// @keyframes show {
//     0% {
//         transform: translate(0, 0);
//         opacity: 1;
//     }
//     100% {
//         transform: translate(0, -30px);
//         opacity: 0;
//     }
// }