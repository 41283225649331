.territories {
    &-wrapper {
        padding-top: 140px;
        padding-bottom: 80px;

        @include tablet {
            padding-top: 64px;
            padding-bottom: 0;
        }
    }

    &-title {
        max-width: calculateRem(333px);

        @include mobile {
            font-size: 32px;
            line-height: 36px;
            max-width: calculateRem(160px);
        }
    }

    &-image {
        margin-top: calculateRem(60px);

        @include tablet {
            margin-top: 0;
        }

        img {
            max-width: 100%;
        }
    }
}