.services {
    display: flex;

    @include tablet {
        flex-direction: column;
    }

    &-wrapper {
        padding-top: 80px;
        padding-bottom: 80px;

        @include tablet {
            padding-top: 64px;
            padding-bottom: 0;
        }

        .container {
            padding-right: 0;
        }
    }

    &__arrows {
        display: flex;
        align-items: center;
        margin-top: 32px;

        @include tablet {
            display: none;
        }

        svg {
            width: 80px;
            height: 80px;
            cursor: pointer;
        }

        .swiper-button-next-services {
            margin-left: 8px;
        }

        .swiper-button-disabled {
            opacity: .8;
            pointer-events: none;
        }
    }

    &-images {
        height: calculateRem(208px);
    }

    &__block {
        min-width: calc(100% - (100% - 318px));
        margin-right: 17px;

        .title {
            max-width: calculateRem(318px);

            @include tablet {
                font-size: 68px;
                line-height: 76px;
                margin-bottom: calculateRem(40px);
                max-width: none;
            }

            @include mobile {
                font-size: 32px;
                line-height: 36px;
                margin-bottom: calculateRem(17px);
            }
        }
    }

    &__slider {
        // max-width: calc(100% - 318px);
        max-width: 100%;

        @include tablet {
            max-width: 100%;
        }

        &__item {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            //width: 318px;
            // border: 1px solid rgba(16, 27, 20, 0.15);
            border-bottom: none;
            background-color: #fff;
            background: url(../images/static/slider-item.png) no-repeat;
            // width: 318px;
            min-height: 430px;

            // &:after {
            //     content: '';
            //     position: absolute;
            //     left: 0;
            //     bottom: 0;
            //     background: url(../images/static/tringle.svg) no-repeat;
            //     width: 100%;
            //     height: 23px;
            // }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &-desc {
        padding: 24px 24px 40px;
        min-height: calculateRem(220px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include tablet_small {
            padding: 12px 16px 32px;
        }

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;

            @include tablet_small {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &-total {
        margin-top: 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
            font-family: Akrobat, sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: var(--color-park);

            @include tablet_small {
                font-size: 28px;
                line-height: 32px;
            }
        }

        span {
            font-family: Commissioner, sans-serif;
            font-weight: 600;
        }

        &__day {
            margin-left: calculateRem(12px);
        }
    }

    .swiper-slide {
        min-width: calculateRem(316px);

        @include tablet {
            min-width: auto;
        }
    }
}