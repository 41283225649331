.buy {
    display: flex;
    min-height: 500px;
    height: 100%;
    justify-content: flex-end;

    @include tablet_small {
        flex-direction: column-reverse;
    }

    &-wrapper {
        position: relative;
        padding: 80px 0;

        @include tablet {
            padding: 64px 0 0;
        }
        
        .container {
            @include tablet_small {
                padding: 0;
            }
        }
    }

    &__image {
        position: absolute;
        left: 0;
        //width: 55%;
        width: 50%;
        height: 75%;

        @include tablet_small {
            position: relative;
            width: 100%;
            margin-top: calculateRem(70px);
        }

        img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            @include tablet {
                width: 100%;
                object-fit: contain;
            }
        }
    }

    &__text {
        width: 50%;
        padding-left: 60px;

        @include tablet_small {
            width: 100%;
            padding: 0 16px;
        }

        &-title {
            font-family: Akrobat;
            font-style: normal;
            font-weight: 800;
            font-size: 100px;
            line-height: 100px;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            max-width: 313px;

            @include tablet {
                font-size: 68px;
                line-height: 76px;
                max-width: calculateRem(400px);
            }

            @include mobile {
                font-size: 44px;
                line-height: 48px;
            }
        }

        &-desc {
            margin-top: 28px;
            font-size: 16px;
            line-height: 24px;
            color: rgba(16, 27, 20, .8);
        }

        &-button {
            margin-top: 44px;

            svg {
                cursor: pointer;
            }
        }
    }
}