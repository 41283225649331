// .news {
//     padding-top: 100px;

//     &__items {
//         display: flex;
//         flex-wrap: wrap;
//         margin: 40px -9px 0;

//         &-item {
//             max-width: 316px;
//             width: 100%;
//             margin: 0 9px 30px;
//             border: 1px solid rgba(16, 27, 20, 0.15);
//             overflow: hidden;
//         }

//         &-img {
//             width: 100%;
//             max-width: 100%;
//             max-height: 220px;
//         }

//         &-date {
//             padding: 24px 24px 0 24px;
//             font-size: 14px;
//             line-height: 16px;
//             color: #101B14;
//             opacity: 0.6;
//             margin-bottom: 4px;
//         }

//         &-text {
//             padding: 0 24px 24px 24px;
//             font-weight: 500;
//             font-size: 18px;
//             line-height: 22px;
//             color: #101B14;
//         }

//         &-button {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             margin-top: 15px;
//         }
//     }
// }


.diractorate__publications {
  padding: calculateRem(80px) 0;

  &-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-blocks {
    display: flex;
    flex-wrap: wrap;
    margin: calculateRem(40px) calc(-18px / 2) 0;
  }

  .publications-block {
    // width: calc((100% / 4) - calc(18px / 2));
    display: flex;
    flex-direction: column;
    margin: 0 calc(18px / 2) calculateRem(30px);
    border: 1px solid rgba(16, 27, 20, 0.15);
    background: $color-white;

    width: calc((100% / 4) - (72px / 4));
    text-decoration: none;
    color: $color-dark;

    @include tablet_small {
      width: calc((100% / 2) - (72px / 2));
    }

    @include mobile {
      width: 100%;
    }

    &__image {
      height: 210px;

      @include tablet {
        @media screen and (orientation: portrait) {
          height: 240px;
        }

        @media screen and (orientation: landscape) {
          height: 150px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__text {
      padding: calculateRem(20px) calculateRem(24px);

      @include desktop_extra_small {
        padding: calculateRem(12px) calculateRem(16px) calculateRem(16px);
      }

      span {
        color: $color-dark-6;
        font-size: calculateRem(14px);
        line-height: calculateRem(16px);
      }

      p {
        margin-top: calculateRem(4px);
        font-weight: 500;
        font-size: calculateRem(18px);
        line-height: calculateRem(22px);

        @include desktop_extra_small {
          font-size: calculateRem(16px);
          line-height: calculateRem(20px);
        }
      }
    }
  }

  &-button {
    margin-top: calculateRem(45px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}