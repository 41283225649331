.order-list {
    padding: calculateRem(60px) 0 calculateRem(160px);

    @include tablet {
        padding: calculateRem(20px) 0 calculateRem(60px);
    }

    &__item {
        padding: calculateRem(20px) 0;
        display: flex;
        border-bottom: 1px solid $color-dark-1;

        @include tablet {
            padding: calculateRem(16px) 0;
        }

        &:last-child {
            border: none;
        }

        &-headline {
            text-transform: uppercase;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $color-dark-6;
        }

        &-name {
            width: 35%;
            
            @include tablet {
                width: 50%;
                font-size: calculateRem(12px);
                line-height: calculateRem(16px);
            }
        }

        &-place {
            width: calc(100% - 35%);

            @include tablet {
                font-size: calculateRem(12px);
                line-height: calculateRem(16px);
            }
        }
    }
}