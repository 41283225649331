.diractorate {
    padding-top: calculateRem(120px);
}

.diractorate-bg-1 {
    margin-top: calculateRem(36px);
    padding-top: calculateRem(55px);
    background: url("~/public/images/green-bg.svg") no-repeat;
    position: relative;

    &:after {
        content: "";
        background: url("~/public/images/dekor-1.svg") no-repeat;
        position: absolute;
        display: block;
        right: -35%;
        bottom: 0;
        height: calculateRem(900px);
        width: calculateRem(1069.77px);
        z-index: -1;
        transform: matrix(0.68, 0.74, 0.74, -0.68, 0, 0);
    }
}

// .diractorate-page {
//     @include tablet {

//     }
// }

.diractorate__information {
    display: flex;
    align-items: center;
    padding-top: calculateRem(90px);
    padding-bottom: calculateRem(80px);

    @include tablet {
        padding-top: calculateRem(16px);
        padding-bottom: calculateRem(64px);
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    &-text {
        max-width: calculateRem(400px);
        margin-left: calculateRem(100px);
        font-size: calculateRem(18px);
        line-height: calculateRem(28px);
        color: $color-dark-8;
        padding-left: 16px;
        padding-right: 16px;

        @include tablet {
            margin-left: 0;
            font-size: calculateRem(14px);
            line-height: calculateRem(20px);
            margin-bottom: calculateRem(64px);
            max-width: none;
        }
    }

    &-img {
        img {
            max-width: 100%;
        }
    }
}

.diractorate__structure {
    padding: calculateRem(80px) 0;

    @include tablet {
        padding: 0 0 calculateRem(20px);
    }

    &-headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-blocks {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1439px) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        @include mobile {
            margin: 0;
        }
    }

    .structure-block {
        background: url("../images/static/s-bg-card.png") no-repeat;
        background-size: contain;
        margin: calculateRem(40px) 0 0;
        width: 318px;
        height: 678px;

        @media screen and (max-width: 1439px) {
            margin: calculateRem(20px) auto 0;
        }

        &__img {
            width: 100%;
            height: 52%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__text {
            padding: calculateRem(24px);
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 320px) {
                padding: calculateRem(16px) calculateRem(20px);
            }

            &-title {
                font-weight: 500;
                font-size: calculateRem(20px);
                line-height: calculateRem(28px);
                margin-bottom: calculateRem(4px);
                min-height: calculateRem(56px);

                @media screen and (max-width: 320px) {
                    font-size: calculateRem(16px);
                    line-height: calculateRem(20px);
                    min-height: auto;
                }
            }

            &-subtitle {
                font-size: calculateRem(14px);
                line-height: calculateRem(20px);
                letter-spacing: 0.01em;
                text-transform: uppercase;
                margin-bottom: calculateRem(14px);
                color: $color-dark-6;

                @media screen and (max-width: 320px) {
                    font-size: calculateRem(12px);
                    line-height: calculateRem(16px);
                    margin-bottom: calculateRem(12px);
                }
            }

            &-subdivision {
                font-size: calculateRem(14px);
                line-height: calculateRem(20px);
                letter-spacing: 0.01em;
                text-transform: uppercase;
                margin-bottom: calculateRem(2px);
                color: $color-dark-6;

                @media screen and (max-width: 320px) {
                    font-size: calculateRem(12px);
                    line-height: calculateRem(16px);
                    margin-bottom: calculateRem(4px);
                }
            }

            &-division {
                font-size: calculateRem(18px);
                line-height: calculateRem(24px);
                word-wrap: break-word;
                margin-bottom: calculateRem(14px);

                @media screen and (max-width: 320px) {
                    font-size: calculateRem(14px);
                    line-height: calculateRem(16px);
                    margin-bottom: calculateRem(12px);
                }
            }

            &-contacts {
                font-size: calculateRem(14px);
                line-height: calculateRem(20px);
                letter-spacing: 0.01em;
                text-transform: uppercase;
                margin-bottom: calculateRem(4px);
                color: $color-dark-6;

                @media screen and (max-width: 320px) {
                    font-size: calculateRem(12px);
                    line-height: calculateRem(16px);
                    margin-bottom: calculateRem(4px);
                }
            }

            a {
                display: inline-block;
                font-size: calculateRem(18px);
                line-height: calculateRem(24px);
                margin-bottom: calculateRem(2px);
                color: $color-dark;
                text-decoration: none;

                @media screen and (max-width: 320px) {
                    display: block;
                    font-size: calculateRem(14px);
                    line-height: calculateRem(16px);
                    margin-bottom: calculateRem(4px);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.diractorate__publications {
    padding: calculateRem(80px) 0 0;

    @include tablet {
        padding: calculateRem(20px) 0 0;
    }

    &-index {
        @include tablet {
            padding: calculateRem(50px) 0 0;
        }
    }

    &-headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-blocks {
        display: flex;
        flex-wrap: wrap;
        margin: calculateRem(40px) calc(-18px / 2) 0;

        @include tablet {
            margin: calculateRem(20px) calc(-18px / 2) 0
        }
    }

    .publications-block {
        // width: calc((100% / 4) - calc(18px / 2));
        margin: 0 calc(18px / 2) calculateRem(30px);
        border: 1px solid rgba(16, 27, 20, 0.15);
        background: $color-white;

        width: calc((100% / 4) - (72px / 4));

        @include tablet_small {
            width: calc((100% / 2) - (72px / 2));
            margin: 0 calc(18px / 2) calculateRem(17px); 
        }

        @include mobile {
            width: 100%;
        }

        &__image {
            img {
                max-width: 100%;
            }
        }

        &__text {
            padding: calculateRem(20px) calculateRem(24px);

            @include tablet {
                padding: calculateRem(12px) calculateRem(16px);
            }

            span {
                color: $color-dark-6;
                font-size: calculateRem(14px);
                line-height: calculateRem(16px);
                letter-spacing: 0.01em;

                @include tablet {
                    font-size: calculateRem(13px);
                    line-height: calculateRem(20px);
                }
            }

            p {
                margin-top: calculateRem(4px);
                font-weight: 500;
                font-size: calculateRem(18px);
                line-height: calculateRem(22px);

                @include tablet {
                    margin-top: 0;
                    font-size: calculateRem(16px);
                    line-height: calculateRem(20px);
                }
            }
        }
    }

    &-button {
        margin-top: calculateRem(45px);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            path.button-green {
                transition: 0.3s ease-in-out;
                fill: $color-green;
            }

            &:hover {
                path.button-green {
                    fill: #7db184;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }
}

// Вопрос ответ
.diractorate-question {
    padding: calculateRem(80px) 0;

    @include tablet {
        padding: 0;
    }

    &__headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;

        @include mobile {
            justify-content: flex-start;
            flex-wrap: wrap;
        }
    }

    &__items {
        margin-top: calculateRem(40px);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include mobile {
            margin-top: calculateRem(16px);
        }
    }

    &__wrapper &__items {
        width: calc(50% - 20px);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include mobile {
            width: 100%;

            &:first-child {
                border-bottom: 1px solid $color-dark-1;
            }

            &:last-child {
                margin-top: calculateRem(14px);
            }
        }
    }

    &__item {
        padding: calculateRem(28px) 0;
        border-bottom: 1px solid $color-dark-1;

        &:last-child {
            border-bottom: 0;
        }

        @include tablet {
            width: 100%;
            padding: calculateRem(14px) 0;

            &:not(:first-child) {
                padding-top: calculateRem(14px);
            }
        }

        @include mobile {
            &:first-child {
                padding-top: 0;
            }
        }

        &-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            p {
                font-weight: 500;
                font-size: calculateRem(24px) !important;
                line-height: calculateRem(32px) !important;
                max-width: calc(100% - 48px);
                transition: color .3s ease-out;
                margin-bottom: 0 !important;

                @include tablet {
                    font-size: calculateRem(18px) !important;
                    line-height: calculateRem(24px) !important;
                }
            }

            svg {
                width: 32px;
                height: 32px;
                transition: transform .3s ease-out;
            }
        }

        &-text {
            max-height: 0;
            max-width: calc(100% - 48px);
            font-size: calculateRem(16px);
            line-height: calculateRem(24px);
            color: $color-dark-8;
            opacity: 0;
            overflow: hidden;
            transition: all .3s linear;

            @include tablet {
                font-size: calculateRem(14px);
                line-height: calculateRem(20px);
                max-width: none;
            }
        }

        &.active {
            .diractorate-question__item-title {
                p {
                    color: $color-green;
                }

                svg {
                    transform: rotate(45deg);
                }
            }

            .diractorate-question__item-text {
                margin-top: calculateRem(28px);
                max-height: 100%;
                opacity: 1;
                transition: all .4s .3s;

                @include mobile {
                    margin-top: calculateRem(14px);
                }
            }
        }
    }
}

.diractorate__structure-content {
    padding: 0;

    img {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .diractorate__structure-blocks {
        flex-wrap: wrap;
    }

    .structure-block {
        background-size: cover;
        width: calc((100% / 3) - 18px);
        min-height: calculateRem(674px);
    }
}

.structure {
    flex-wrap: wrap;

    &__wrapper {
        padding: 0;
    }
}
