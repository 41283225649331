.red-book-tabs__content {
  display: none; /* по умолчанию прячем все блоки */
}
.red-book-tabs__content.active {
  display: flex; /* по умолчанию показываем нужный блок */
}

.red-book-tabs {
  &__caption {
    display: flex;
    padding-left: 0;
    list-style: none;

    li {
      position: relative;
      margin-right: calculateRem(60px);
      cursor: pointer;
      font-weight: 600;
      font-size: calculateRem(16px);
      line-height: calculateRem(20px);
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $color-dark-5;

      @include tablet_small {
        font-size: calculateRem(13px);
        line-height: calculateRem(20px);
        margin-right: calculateRem(24px);
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: $color-dark;

        &:before {
          content: '';
          width: 100%;
          height: 2px;
          background-color: var(--bg-park);
          position: absolute;
          left: 0;
          bottom: -4px;
        }
      }

      &:after {
        content: attr(data-number);
        position: absolute;
        font-size: calculateRem(12px);
        line-height: calculateRem(14px);
        top: 0;
        right: -20px;

        @include tablet_small {
          font-weight: 400;
          font-size: calculateRem(10px);
          line-height: calculateRem(12px);
          right: -14px;
        }
      }
    }
  }
}

.rb-tabs-list {
  width: 65%;
  //padding: calculateRem(50px) calculateRem(100px) calculateRem(50px) 0;
  padding: 0 calculateRem(50px) calculateRem(50px) 0;
  max-height: 600px;
  overflow-y: scroll;
  margin-top: calculateRem(50px);
  margin-right: calculateRem(50px);

  //scroll

  &::-webkit-scrollbar { /* 1 - скроллбар */
    width: 4px;
    height: 4px;
    background-color: rgba(255, 255, 255, .1);
  }

  &::-webkit-scrollbar-button { /* 2 - кнопка */

  }

  &::-webkit-scrollbar-track { /* 3 - трек */
  }

  &::-webkit-scrollbar-track-piece { /* 4 - видимая часть трека */
  }

  &::-webkit-scrollbar-thumb { /* 5 - ползунок */
    //border-radius: 2px;
    background-color: var(--bg-park);
  }

  &::-webkit-scrollbar-corner { /* 6 - уголок */
  }

  &::-webkit-resizer { /* 7 - изменение размеров */
  }

  @include tablet_small {
    //padding: calculateRem(24px) 0 0;
    padding: 0 calculateRem(25px) calculateRem(50px) 0;
    width: 100%;
    margin-right: 0;
    max-height: fit-content;
  }

  &__element {
    display: flex;
    align-items: center;
    padding: calculateRem(16px) 0;
    cursor: pointer;
    position: relative;

    @include tablet_small {
      padding-right: calculateRem(30px);
    }

    &:not(:first-child) {
      border-top: 1px solid $color-dark-1;
    }

    &:hover {
      p {
        color: var(--color-park);
      }

      &::after {
        content: '';
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='61' height='16' viewBox='0 0 61 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M60.7071 8.7076C61.0976 8.31708 61.0976 7.68391 60.7071 7.29339L54.3432 0.929425C53.9526 0.538901 53.3195 0.538901 52.9289 0.929425C52.5384 1.31995 52.5384 1.95311 52.9289 2.34364L58.5858 8.00049L52.9289 13.6573C52.5384 14.0479 52.5384 14.681 52.9289 15.0716C53.3195 15.4621 53.9526 15.4621 54.3432 15.0716L60.7071 8.7076ZM-8.74228e-08 9.00049L60 9.00049L60 7.00049L8.74228e-08 7.00049L-8.74228e-08 9.00049Z' fill='%23006EA6'/%3e%3c/svg%3e");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        width: 60px;
        height: 10px;
        display: block;

        @include tablet_small {
          width: 30px;
        }
      }
    }

    span {
      font-size: calculateRem(12px);
      line-height: calculateRem(14px);

      @include tablet_small {
        font-size: calculateRem(10px);
        line-height: calculateRem(12px);
      }
    }

    p {
      margin-left: calculateRem(16px);
      font-weight: 500;
      font-size: calculateRem(20px);
      line-height: calculateRem(28px);
      transition: .5s ease-in-out;

      @include tablet_small {
        font-size: calculateRem(16px);
        line-height: calculateRem(20px);
      }
    }
  }

  .load_more {
    display: flex;
    justify-content: center;
    margin-top: calc(44px - 16px);

    svg {
      cursor: pointer;

      @include tablet_small {
        width: calculateRem(153px);
        height: calculateRem(51px);
      }
    }
  }
}

.rb-tabs-image {
  //width: 35%;
  width: 486px;
  height: 577px;
  object-fit: cover;
  padding-top: calculateRem(66px);

  @include tablet_small {
    display: none;
  }

  img {
    width: 100%;
    //height: 100%;
    //object-fit: cover;
  }
}

.rb-overlay {
  display: none;
  background-color: rgba(16, 27, 20, .25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: scroll;

  &.open {
    display: block;
  }
}

.rb-popup {
  position: relative;
  background: $color-white;
  margin: calculateRem(60px);
  display: flex;

  @include tablet_small {
    flex-direction: column;
    margin: calculateRem(16px);
  }

  &__img {
    width: 50%;
    display: flex;
    align-items: center;

    @include tablet_small {
      width: 100%;
    }

    img {
      width: 100%;
      //height: 100%;
      object-fit: cover;
      object-position: center;

      @include tablet_small {
        //max-height: calculateRem(360px);
        max-height: calculateRem(500px);
      }
    }
  }

  &__text {
    width: 50%;
    padding: calculateRem(80px);

    @include desktop_base {
      padding: calculateRem(40px);
    }

    @include tablet_small {
      width: 100%;
      padding: calculateRem(24px) calculateRem(16px);
    }

    .h3 {
      max-width: calculateRem(420px);
      margin-bottom: calculateRem(20px);

      @include desktop_base {
        font-size: calculateRem(32px);
        line-height: calculateRem(44px);
      }

      @include tablet_small {
        margin-bottom: calculateRem(12px);
        font-size: calculateRem(32px);
        line-height: calculateRem(36px);
      }
    }
  }

  &__description {
    max-width: calculateRem(420px);

    p {
      font-size: calculateRem(16px);
      line-height: calculateRem(24px);
      color: $color-dark-8;
      margin-bottom: calculateRem(24px);

      @include desktop_base {
        font-size: calculateRem(14px);
        line-height: calculateRem(20px);
      }

      @include tablet_small {
        margin-bottom: calculateRem(16px);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: calculateRem(28px);
    right: calculateRem(28px);

    @include tablet {
      top: calculateRem(16px);
      right: calculateRem(16px);
    }

    svg {
      @include desktop {
        width: calculateRem(32px);
        height: calculateRem(32px);
      }

      circle {
        @include tablet_small {
          fill: $color-white;
          opacity: 1;
        }
      }
    }
  }
}