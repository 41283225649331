.figure {
  position: absolute;
  z-index: -1;

  svg:not(:root) {
    overflow: visible;
  }

  &-grey {
    right: 0;
    top: 0;

    @include tablet {
      right: -250px;
    }

    @include tablet_small {
      right: -500px;
    }
  }

  &-2 {
    top: calculateRem(110px);

    @include tablet {
      @media screen and (orientation: portrait) {
        //top: 40px;
        top: calculateRem(370px);
      }
    }

    img {
      @include tablet {
        width: 80%;
      }
    }
  }

  &-3 {
    top: calc(70px + 463px);
    right: 0;

    @include tablet {
      width: 80%;

      @media screen and (orientation: portrait) {
        //right: -75px;
        top: calc(70px + 150px);
      }
    }

    img {
      @include tablet {
        position: absolute;
        right: 0;
        width: 80%;
      }
    }
  }

  &-4 {
    top: -30px;
    left: 0;

    @include tablet {
      @media screen and (orientation: portrait) {
        top: 320px;
        left: 0;
      }
    }

    img {
      @include tablet {
        width: 80%;
      }
    }
  }

  &-5 {
    right: 0;


    img {
      @include tablet {
        @media screen and (orientation: portrait) {
          position: absolute;
          right: -400px;
        }
      }
    }
  }

  &-index {
    top: -35px;
    right: 0;

    svg.mobile {
      display: none;
    }

    @include desktop_extra_small {
      top: 0;

      svg:first-child {
        display: none;
      }

      svg.mobile {
        display: block;
      }
    }

    //@include tablet_small {
    //  top: -400px;
    //  right: -150px;
    //}
  }

  &-decorate-directorate {
    background: url(../images/static/figure-decorate-directorate.svg) no-repeat;
    background-size: cover;
    // top: calculateRem(-100px);
    top: calculateRem(-140px);
    right: 0;
    width: calculateRem(605px);
    height: 100%;
    min-height: calculateRem(609px);

    @include tablet {
      background: url(../images/static/figure-decorate-directorate-mobile.svg) no-repeat;
      top: calculateRem(-75px);
      width: calculateRem(304px);
      min-height: calculateRem(334px);
    }
  }

  &-green-bottom {
    background: url(../images/static/figure-green-bottom.svg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: calculateRem(947px);
    left: 0;
    bottom: calculateRem(150px);
  }

  &-directorate__publications {
    top: -14rem;
    right: 0;

    @include desktop_extra_small {
      display: none;
    }
  }

  &-contacts {
    top: 0;
    right: 0;
  }
}
