.useful-links {
    display: flex;
    margin: 0 -8px;

    @include desktop {

    }

    @include tablet {
        // flex-wrap: wrap;
        flex-direction: column;
        margin: 0;
    }

    &-wrapper {
        padding-top: 80px;
        padding-bottom: 80px;

        @include tablet {
            padding-top: 0;
            padding-bottom: 64px;
        }
    }

    &__title {
        font-family: Akrobat;
        font-style: normal;
        font-weight: 800;
        font-size: 100px;
        line-height: 100px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        margin-bottom: 48px;

        @include tablet {
            font-size: 68px;
            line-height: 76px;
        }

        @include mobile {
            font-size: 44px;
            line-height: 48px;
            max-width: 190px;
        }
    }

    &__item {
        width: calc(25% - 8px * 4);
        height: 300px;
        margin: 0 8px;
        border: 1px solid rgba(16, 27, 20, 0.15);
        padding: calculateRem(24px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #101B14;
        text-decoration: none;
        background: $color-white;

        @include tablet {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: calculateRem(105px);
            margin: 0;
            padding: calculateRem(16px);
            margin-bottom: calculateRem(12px);

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-image {
            svg {
                @include desktop_medium {
                    width: 100%;
                    // height: 100%;
                }

                @include tablet {
                    width: calculateRem(72px);
                    height: calculateRem(72px);
                }

                rect, path {
                    stroke: var(--color-park);
                    // fill: var(--color-park);
                }
            }
        }

        &-text {
            
            @include tablet {
                margin-left: calculateRem(16px);
            }

            p {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 12px;

                @include tablet {
                    font-size: calculateRem(16px);
                    line-height: calculateRem(20px);
                    margin-bottom: calculateRem(4px);
                }
            }

            span {
                font-size: 14px;
                line-height: 16px;
                color: rgba(16, 27, 20, .6);

                @include tablet {
                    font-size: calculateRem(13px);
                }
            }
        }
    }
}
