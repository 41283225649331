.contests {
  padding-top: calculateRem(80px);
  padding-bottom: calculateRem(80px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  //@include desktop_medium {
  //  align-items: flex-start;
  //  flex-direction: column;
  //}

  @include tablet {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: calculateRem(40px);
  }

  @include mobile {

  }

  &-title {
    max-width: calculateRem(282px);
    margin-right: calculateRem(50px);

    @include tablet {
      margin-bottom: calculateRem(17px);
    }

    p {
      @extend .h2;
    }

    &__show-more {
      margin-top: calculateRem(45px);
      display: block;

      @include tablet {
        display: none;
      }
    }
  }

  &-items {
    display: flex;

    @include desktop_medium {
      width: 100%;
    }

    @include mobile {
      flex-wrap: wrap;
    }

    &__item {
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      max-width: calculateRem(318px);
      width: 100%;
      border: 1px solid rgba(16, 27, 20, 0.15);
      margin-right: calculateRem(18px);
      text-decoration: none;
      color: inherit;

      @include desktop_medium {
        max-width: calc(100% / 3);
      }

      @include mobile {
        width: 100%;
        max-width: 100%;
        margin-bottom: calculateRem(17px);
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;

        @include mobile {
          margin-bottom: 0;
        }
      }

      &-img {
        width: 100%;
        height: 220px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-text {
        padding: calculateRem(20px) calculateRem(24px);

        span {
          color: $color-dark-6;
          font-size: calculateRem(14px);
          line-height: calculateRem(16px);
        }

        p {
          margin-top: calculateRem(4px);
          font-weight: 500;
          font-size: calculateRem(18px);
          line-height: calculateRem(22px);
        }
      }
    }
  }

  &__show-more_mobile {
    display: none;

    @include tablet {
      display: block;
      margin: 28px auto 0;
    }

    @include mobile {
      display: block;
      margin: 28px auto 0;
    }
  }
}
  