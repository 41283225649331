.partners {
    padding: calculateRem(80px) 0;
    display: flex;
    justify-content: space-between;

    @include desktop {
      flex-direction: column;
    }

    @include tablet {
      padding: calculateRem(44px) 0 0;
    }
  
    &__left {
      max-width: 318px;
  
      &-title {
        @extend .h2;
        margin-bottom: calculateRem(45px);

        @include tablet {
          margin-bottom: calculateRem(15px);
        }
      }

      a {
        @include desktop {
          display: none;
        }
      }
    }
  
    &__right {
      ul {
        list-style: none;
        padding-left: 0;
        display: flex;
        
        @include tablet {
          flex-wrap: wrap;
        }
  
        li {
          width: calculateRem(150px);
          height: calculateRem(150px);
          overflow: hidden;
          // border-radius: 50%;
          // border: 1px solid rgba(16, 27, 20, 0.15);
          margin-right: calculateRem(16px);
          display: flex;
          align-items: center;
          justify-content: center;
  
          &:last-child {
            margin-right: 0;
          }

          @include tablet {
            width: calc(100% / 3);
            height: auto;
            margin-right: 0;
          }
  
          img {
            max-width: 100%;
            max-height: 100%;

            @include tablet {
              max-width: 90%;
              max-height: 90%;
            }
          }
        }
      }
    }
  }
  