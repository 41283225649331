.cookies {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='341' height='241' viewBox='0 0 341 241' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0)'%3e%3cpath d='M36.2976 21.9247L-0.5 140.5L20.381 282.942L123.46 430.102L373.579 462.72V15.9297L290.206 0L114.365 21.9247H36.2976Z' fill='%23EDF6F1'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0'%3e%3crect width='342' height='241' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
    background-repeat: no-repeat;
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;
    min-width: calculateRem(342px);
    min-height: calculateRem(240px);
    padding: calculateRem(56px) calculateRem(21px) calculateRem(24px) calculateRem(32px);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile {
        background-position: 50% 0;
        padding: calculateRem(38px) calculateRem(16px) calculateRem(20px);
        min-width: calculateRem(320px);
    }

    &__text {
        max-width: calculateRem(300px);
        font-size: calculateRem(16px);
        line-height: calculateRem(20px);
        text-align: center;
        margin-bottom: calculateRem(20px);

        @include mobile {
            font-size: calculateRem(14px);
            line-height: calculateRem(20px);
        }
    }

    &__btn {
        cursor: pointer;
        transition: .3s ease-in-out;

        @include mobile {
            width: calculateRem(162px);
            height: calculateRem(39px);
        }

        path.button-green {
            transition: .3s ease-in-out;
            fill: $color-green;
        }

        &:hover {
            path.button-green {
                fill: #7DB184;
                transition: .3s ease-in-out;
            }
        }
    }
}