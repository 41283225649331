.red-book-block {
    margin-top: calculateRem(290px);
    margin-bottom: calculateRem(200px);
    min-height: calculateRem(600px);
    display: flex;
    align-items: center;

    @include tablet {
        flex-direction: column;
        margin-bottom: 0;
    }

    @include mobile {
        margin-top: calculateRem(85px);
    }

    .environment-park__desc {
        padding-bottom: calculateRem(150px);

        @include tablet {
            padding-bottom: 0;
        }
    }
    
    .title {
        @include mobile {
            max-width: calculateRem(195px);
            font-size: 32px;
            line-height: 36px;
        }
    }

    .environment-park__image {
        @include mobile {
            margin-top: calculateRem(40px);
        }
    }

    &__svg {
        position: absolute;
        right: 0;
        bottom: -148px;
        width: calc(100% - (100% - 936px));
        height: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        @include tablet {
            display: none;
        }

        &_mobile {
            display: none;

            @include tablet {
                display: block;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                flex-direction: column;
                text-align: center;
                height: fit-content;
                width: calculateRem(244px);
                height: calculateRem(326px);
                bottom: 40px;
                z-index: 1;
            }
        }
    }

    &__numbers {
        //background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 936 280' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 40.0005L936 0.000488281V280L59 271.429L0 40.0005Z' fill='#{var(--bg-park-svg)}' /%3e%3c/svg%3e");
        position: absolute;
        right: 0;
        bottom: -148px;
        width: calc(100% - (100% - 936px));
        height: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        @include tablet {
            position: relative;
            //background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='244' height='326' viewBox='0 0 244 326' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 11.5148L244 0L231.5 326L27.5 318.5L0 11.5148Z' fill='red'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center;
            flex-direction: column;
            text-align: center;
            height: fit-content;
            padding: calculateRem(28px) 0;
            width: 100%;
            bottom: 40px;
            z-index: 2;
        }

        .item {
            padding: 0 55px;
            border-right: 1px solid rgba(255, 255, 255, .2);
            color: white;

            @include tablet {
                padding: 0;
                border-bottom: 1px solid rgba(255, 255, 255, .2);
                border-right: none;
                padding: calculateRem(16px) 0 calculateRem(16px);
            }

            &:first-child {
                padding-left: 0;

                @include tablet {
                    padding-top: 0;
                }
            }

            &:last-child {
                padding-right: 0;
                border: none;

                @include tablet {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }

            p {
                font-family: Akrobat;
                font-style: normal;
                font-weight: 800;
                font-size: 100px;
                line-height: 76px;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                margin-bottom: 8px;

                @include tablet {
                    margin-bottom: 0;
                    font-size: 48px;
                    line-height: 48px;
                }
            }

            span {
                font-size: 20px;
                line-height: 24px;

                @include tablet {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}