.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  min-height: 675px;
  
  @include tablet {
    min-height: auto;
  }

  .title {
    margin-top: 0;
    margin-bottom: 24px;
    max-width: 471px;

    @include tablet {
      font-size: calculateRem(44px);
      line-height: calculateRem(48px);
      margin-bottom: calculateRem(12px);
    }
  }
  
  .description {
    margin-bottom: 52px;
    font-size: 20px;
    line-height: 28px;
    color: rgba(16, 27, 20, 0.8);
    max-width: 402px;

    @include tablet {
      font-size: calculateRem(14px);
      line-height: calculateRem(20px);
      margin-bottom: calculateRem(28px);
    }
  }
}