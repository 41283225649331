.environment {
    padding-top: 165px;
    position: relative;
    padding-bottom: 95px;

    @include tablet_small {
        padding-top: 60px;
        padding-bottom: 48px;
    }

    &__desc {
        max-width: 484px;
        font-size: 20px;
        line-height: 28px;

        @include tablet_small {
            max-width: 100%;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__bird {
        position: absolute;
        top: 18px;
        right: 0;
        width: 410px;
        height: 410px;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        @include tablet {
            right: -140px;
        }
        
        @include tablet_small {
            display: none;
        }
    }
}

.environment-park {
    display: flex;
    justify-content: flex-end;

    &__desc {
        max-width: calculateRem(462px);

        @include desktop_base {
            max-width: calculateRem(350px);
        }

        @include tablet {
            max-width: 100%;
        }

        .title {
            @include desktop_base {
                font-size: 68px;
                line-height: 76px;
                max-width: calculateRem(320px);
            }

            @include mobile {
                font-size: 44px;
                line-height: 48px;
                max-width: calculateRem(205px);
            }
        }

        .text {
            margin-top: calculateRem(40px);

            @include mobile {
                margin-top: calculateRem(16px); 
            }

            p {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 32px;
                color: $color-dark-8;

                &:last-child {
                    margin-bottom: 0;
                }

                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        height: 100%;
        width: 55%;

        @include tablet {
            position: relative;
            margin-top: calculateRem(58px);
            width: 100%;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            //object-fit: cover;
        }
    }
}