.parthners-slider {
    display: flex;

    @include desktop_extra_small {
        flex-direction: column;
    }

    &-wrapper {
        padding: 80px 0 160px 0;

        @include tablet {
            padding: 0 0 64px 0;
        }

        .container {
            @include desktop_extra_small {
                padding-right: 0;
            }

            @include mobile {
                padding-right: 16px;
            }
        }
    }

    &__title {
        min-width: 318px;

        p {
            font-family: Akrobat, sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 68px;
            line-height: 76px;
            letter-spacing: -0.02em;
            text-transform: uppercase;

            @include mobile {
                font-size: 32px;
                line-height: 36px;
            }
        }
    }

    &__arrows {
        display: flex;
        margin: 32px -4px 0;

        @include desktop_extra_small {
            display: none;
        }

        div {
            margin: 0 4px;
            
            svg {
                cursor: pointer;
            }
        }
    }

    &__items {
        max-width: 100%;

        @include desktop_extra_small {
            margin-top: calculateRem(40px);
        }

        @include mobile {
            margin-top: calculateRem(12px);
        }

        .swiper-wrapper {
            @include mobile {
                flex-wrap: wrap;
                margin: calculateRem(-4px) 0;
            }
        }

        .swiper-slide {

            @include mobile {
                width: calc(100% / 3);
                margin: calculateRem(4px) 0;
            }

            img {
                max-width: 100%;
                max-height: 100%;

                @include mobile {
                    width: 90%;
                }
            }
        }
    }
}