.park-page {
    overflow: hidden;
}

.bird {
    position: absolute;
    right: -50px;
    top: 0;
    width: 418px;
    height: 418px;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    @include mobile {
        width: 155px;
        height: 155px;
        right: -40px;
        top: 22px;
    }
}

.red-book-planet {
    margin-top: 196px;
    padding-bottom: 80px;

    @include tablet {
        margin-top: 0;
    }

    &__headline {
        display: flex;
        align-items: center;
        margin-bottom: 48px;

        &-item {
            margin-right: 40px;
            position: relative;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            opacity: .5;
            cursor: pointer;

            &.active {
                opacity: 1;

                &:before {
                    content: '';
                }
            }

            &:before {
                content: none;
                position: absolute;
                bottom: -4px;
                background-color: #AFB420;
                display: block;
                height: 2px;
                width: 100%;
            }

            &:after {
                position: absolute;
                content: attr(data-number);
                top: 0;
                right: -23px;
                display: block;
                width: 15px;
                height: 15px;
                font-size: 12px;
                line-height: 14px;

            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__items {
        display: none;
        width: 100%;
        justify-content: space-between;

        &.active {
            display: flex;
        }
    }

    &__item {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(16, 27, 20, .1);
        width: 736px;
        cursor: pointer;

        span {
            font-size: 12px;
            line-height: 14px;
        }

        p {
            margin-left: 16px;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;

        }

        &.active {
            p {
                color: #006EA6;
            }
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border: none;
            padding-bottom: 0;
        }

        &:after {
            content: '';
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='61' height='16' viewBox='0 0 61 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M60.7071 8.70809C61.0976 8.31756 61.0976 7.6844 60.7071 7.29388L54.3432 0.929914C53.9526 0.539389 53.3195 0.539389 52.9289 0.929913C52.5384 1.32044 52.5384 1.9536 52.9289 2.34413L58.5858 8.00098L52.9289 13.6578C52.5384 14.0484 52.5384 14.6815 52.9289 15.072C53.3195 15.4626 53.9526 15.4626 54.3432 15.072L60.7071 8.70809ZM-8.74228e-08 9.00098L60 9.00098L60 7.00098L8.74228e-08 7.00098L-8.74228e-08 9.00098Z' fill='%23006EA6'/%3e%3c/svg%3e ");

        }
    }

    &__image {
        max-width: 486px;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 44px;
    }
}

.hidden-text {
    display: none;
}

// .rb-overlay {
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     z-index: 99999;
//     position: fixed;
//     overflow: auto;
//     background-color: rgba(16, 27, 20, .25);
//     display: none;

//     &.active {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }
// }

// .red-book-popup {
//     background-color: #fff;
//     display: flex;
//     max-height: 90vh;
//     position: relative;

//     @media (max-width: 1024px) {
//         flex-direction: column;
//         max-height: none;
//     }

//     &__wrapper {
//         // position: absolute;
//         // top: 0;
//         // right: 0;
//         // bottom: 0;
//         // right: 0;
//         margin: 20px 60px;
//         z-index: 10;
//         display: none;

//         &.active {
//             display: block;
//         }

//         @media (max-width: 1024px) {
//             margin: 20px;
//         }

//         @media (max-width: 575px) {
//             margin: 20px 0;
//         }
//     }

//     &__close {
//         position: absolute;
//         top: 28px;
//         right: 28px;

//         @media (max-width: 1024px) {
//             top: 16px;
//             right: 16px;
//         }

//         svg {
//             width: 80px;
//             height: 80px;

//             @media (max-width: 1024px) {
//                 width: 32px;
//                 height: 32px;
//                 fill: white;
//             }
//         }

//         &:hover {
//             cursor: pointer;
//         }
//     }

//     &__image {
//         width: 50%;

//         @media (max-width: 1024px) {
//             width: 100%;
//         }

//         img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;

//             @media (max-width: 1024px) {
//                 width: 100%;
//                 max-height: 500px;
//             }
//         }
//     }

//     &__desc {
//         width: 50%;
//         padding: 80px;

//         @media (max-width: 1024px) {
//             width: 100%;
//             padding: 24px 16px;
//         }
//     }

//     &__title {
//         font-family: Akrobat;
//         font-style: normal;
//         font-weight: 800;
//         font-size: 56px;
//         line-height: 64px;
//         letter-spacing: -0.02em;
//         text-transform: uppercase;
//     }

//     &__text {
//         margin-top: 20px;
//         overflow-y: scroll;
//         max-height: 450px;

//         p {
//             margin-bottom: 24px;

//             &:last-child {
//                 margin-bottom: 0;
//             }
//         }
//     }
// }

.ya-map-park {
    &-wrapper {
        padding: 80px 0;

        @include tablet {
            padding: 64px 0;
        }

        @include mobile {
            padding: 16px 0 64px;
        }
    }

    min-height: 600px;
    height: 100px;
    width: 100%;
    background-color: grey;

    @include tablet {
        min-height: calculateRem(400px);
    }

    @include mobile {
        min-height: calculateRem(240px);
    }
}

.see-also {
    padding-top: 160px;

    @include tablet_small {
        padding-top: 80px;
    }

    @include mobile {
        padding-top: 64px;
    }

    .parks-page__slider {
        //overflow: visible;
    }

    &__title {
        margin-left: calculateRem(150px);
        margin-bottom: calculateRem(48px);
        // max-width: 573px;
        font-family: Akrobat;
        font-style: normal;
        font-weight: 800;
        font-size: calculateRem(100px);
        line-height: calculateRem(100px);
        letter-spacing: -0.02em;
        text-transform: uppercase;

        @include tablet {
            font-size: calculateRem(68px);
            line-height: calculateRem(76px);
            // max-width: 400px;
            margin-left: calculateRem(100px);

            @media screen and (orientation: landscape) {
                margin-left: 0;
            }
        }

        @include tablet_small {
            margin-left: 0;
        }

        @include mobile {
            max-width: 100%;
            font-size: calculateRem(44px);
            line-height: calculateRem(48px);
            margin-bottom: calculateRem(20px);
        }
    }
}