.parks-page {
  // padding-bottom: calculateRem(160px);
  overflow-x: hidden;

  .swiper-container {
    margin-top: calculateRem(48px);
    overflow: visible;
    // padding-bottom: 120px;
  }

  &__slider {

    &-items {
      width: 100vw;
      margin-top: calculateRem(48px);

      @include tablet {
        margin-top: calculateRem(20px);
      }
    }

    &-slide {
      position: relative;
      max-height: 708px;
      overflow: hidden;

      @include desktop_small {
        max-height: 533px;
      }

      @include tablet {
        max-height: 400px;
      }

      @include mobile {
        max-height: 193px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      p {
        position: absolute;
        left: calculateRem(48px);
        bottom: calculateRem(40px);
        font-family: Akrobat, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: calculateRem(56px);
        line-height: calculateRem(64px);
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: $color-white;

        @include tablet {
          left: calculateRem(16px);
          bottom: calculateRem(12px);
          font-size: calculateRem(24px);
          line-height: calculateRem(28px);
        }
      }
    }

    &-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        &:first-child {
          margin-right: calculateRem(16px);

          @include tablet {
            margin-right: calculateRem(4px);
          }
        }

        svg {
          cursor: pointer;

          @include tablet {
            width: calculateRem(40px);
            height: calculateRem(40px);
          }

          circle {
            stroke: var(--color-park);
          }

          path {
            fill: var(--color-park);
          }

          &:hover {
            circle {
              fill: var(--color-park);
            }
          }
        }
      }
    }

    &-bottom {
      max-width: 1320px;
      width: 87vw;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: calculateRem(36px);

      @include desktop_small {
        //display: none;
        padding-top: 1.25rem;
      }

      .swiper-pagination {
        width: 70%;
        position: relative;
        background: rgba(16, 27, 20, .15);
        transform: translate3d(0, 0, 0);
        transition: .3s opacity;
        height: 2px;

        @include tablet {
          height: 1px;
        }

        @include mobile {
          width: 45%;
        }

        .swiper-pagination-progressbar-fill {
          background: $color-dark;
          transform-origin: left top;
          transform: scale(0);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }

      .swiper-number {
        font-size: calculateRem(20px);
        line-height: calculateRem(24px);

        @include tablet {
          font-size: calculateRem(13px);
          line-height: calculateRem(20px);
        }
      }
    }
  }
}