.hero-park {
    align-items: center;
    justify-content: center;
    overflow: hidden;

    main {
        @include tablet_small {
            min-height: calculateRem(500px);
        }
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -3px;
        background: url(../images/static/slider-figure.svg) 100% 0 no-repeat;
        background-size: cover;
        z-index: 1;
        width: 45%;
        height: calculateRem(40px);

        @include tablet_small {
            height: calculateRem(10px);
        }
    }

    &__description {
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        font-size: calculateRem(20px);
        line-height: calculateRem(24px);
        letter-spacing: 0.01em;
        max-width: 90%;

        @include tablet_small {
            font-size: calculateRem(12px);
            line-height: calculateRem(16px);
        }
    }

    &__title {
        margin-top: calculateRem(8px);
        font-family: Akrobat, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: calculateRem(140px);
        line-height: calculateRem(140px);
        letter-spacing: -0.02em;

        @include tablet_small {
            font-size: calculateRem(48px);
            line-height: calculateRem(52px);
        }
    }
}
